export const user = [
    {
      "name": "Alice Johnson",
      "username": "alice_j",
      "about": "Tech enthusiast and developer.",
      "followers": 1200,
      "rank": 5,
      "image_url": "https://randomuser.me/api/portraits/women/1.jpg"
    },
    {
      "name": "Bob Smith",
      "username": "bob_smith",
      "about": "Lover of all things tech and coffee.",
      "followers": 850,
      "rank": 12,
      "image_url": "https://randomuser.me/api/portraits/men/1.jpg"
    },
    {
      "name": "Charlie Brown",
      "username": "charlie_b",
      "about": "Music producer and DJ.",
      "followers": 2200,
      "rank": 2,
      "image_url": "https://randomuser.me/api/portraits/men/2.jpg"
    },
    {
      "name": "Dana White",
      "username": "dana_white",
      "about": "Fitness coach and nutritionist.",
      "followers": 1750,
      "rank": 7,
      "image_url": "https://randomuser.me/api/portraits/women/2.jpg"
    },
    {
      "name": "Eve Black",
      "username": "eve_black",
      "about": "Travel blogger and photographer.",
      "followers": 1900,
      "rank": 4,
      "image_url": "https://randomuser.me/api/portraits/women/3.jpg"
    },
    {
      "name": "Frank Green",
      "username": "frank_green",
      "about": "Digital marketer and SEO expert.",
      "followers": 950,
      "rank": 11,
      "image_url": "https://randomuser.me/api/portraits/men/3.jpg"
    },
    {
      "name": "Grace Lee",
      "username": "grace_lee",
      "about": "Graphic designer and artist.",
      "followers": 3000,
      "rank": 1,
      "image_url": "https://randomuser.me/api/portraits/women/4.jpg"
    },
    {
      "name": "Henry Taylor",
      "username": "henry_taylor",
      "about": "Entrepreneur and startup mentor.",
      "followers": 1100,
      "rank": 8,
      "image_url": "https://randomuser.me/api/portraits/men/4.jpg"
    },
    {
      "name": "Ivy Walker",
      "username": "ivy_walker",
      "about": "Chef and food blogger.",
      "followers": 1600,
      "rank": 6,
      "image_url": "https://randomuser.me/api/portraits/women/5.jpg"
    },
    {
      "name": "Jack Turner",
      "username": "jack_turner",
      "about": "Fitness enthusiast and trainer.",
      "followers": 700,
      "rank": 15,
      "image_url": "https://randomuser.me/api/portraits/men/5.jpg"
    },
    {
      "name": "Kara Miller",
      "username": "kara_miller",
      "about": "Fashion designer and stylist.",
      "followers": 2400,
      "rank": 3,
      "image_url": "https://randomuser.me/api/portraits/women/6.jpg"
    },
    {
      "name": "Leo Adams",
      "username": "leo_adams",
      "about": "Tech reviewer and gadget lover.",
      "followers": 1300,
      "rank": 9,
      "image_url": "https://randomuser.me/api/portraits/men/6.jpg"
    },
    {
      "name": "Mia Perez",
      "username": "mia_perez",
      "about": "Yoga instructor and wellness coach.",
      "followers": 950,
      "rank": 14,
      "image_url": "https://randomuser.me/api/portraits/women/7.jpg"
    },
    {
      "name": "Nina Young",
      "username": "nina_young",
      "about": "Journalist and writer.",
      "followers": 1800,
      "rank": 5,
      "image_url": "https://randomuser.me/api/portraits/women/8.jpg"
    },
    {
      "name": "Oscar Hill",
      "username": "oscar_hill",
      "about": "Gaming enthusiast and streamer.",
      "followers": 1200,
      "rank": 10,
      "image_url": "https://randomuser.me/api/portraits/men/7.jpg"
    },
    {
      "name": "Paul King",
      "username": "paul_king",
      "about": "Photographer and videographer.",
      "followers": 800,
      "rank": 16,
      "image_url": "https://randomuser.me/api/portraits/men/8.jpg"
    },
    {
      "name": "Quinn Scott",
      "username": "quinn_scott",
      "about": "Financial advisor and investor.",
      "followers": 1350,
      "rank": 9,
      "image_url": "https://randomuser.me/api/portraits/men/9.jpg"
    },
    {
      "name": "Rachel Green",
      "username": "rachel_green",
      "about": "Blogger and content creator.",
      "followers": 2100,
      "rank": 4,
      "image_url": "https://randomuser.me/api/portraits/women/9.jpg"
    },
    {
      "name": "Sam Brown",
      "username": "sam_brown",
      "about": "Digital artist and animator.",
      "followers": 1150,
      "rank": 10,
      "image_url": "https://randomuser.me/api/portraits/men/10.jpg"
    },
    {
      "name": "Tina Lee",
      "username": "tina_lee",
      "about": "Chef and culinary instructor.",
      "followers": 900,
      "rank": 13,
      "image_url": "https://randomuser.me/api/portraits/women/10.jpg"
    },
    {
      "name": "Uma Patel",
      "username": "uma_patel",
      "about": "Fashion influencer and stylist.",
      "followers": 2200,
      "rank": 2,
      "image_url": "https://randomuser.me/api/portraits/women/11.jpg"
    },
    {
      "name": "Victor Chen",
      "username": "victor_chen",
      "about": "Tech blogger and programmer.",
      "followers": 1400,
      "rank": 8,
      "image_url": "https://randomuser.me/api/portraits/men/11.jpg"
    },
    {
      "name": "Wendy Parker",
      "username": "wendy_parker",
      "about": "Travel photographer and adventurer.",
      "followers": 1900,
      "rank": 3,
      "image_url": "https://randomuser.me/api/portraits/women/12.jpg"
    },
    {
      "name": "Xander White",
      "username": "xander_white",
      "about": "Musician and songwriter.",
      "followers": 950,
      "rank": 14,
      "image_url": "https://randomuser.me/api/portraits/men/12.jpg"
    },
    {
      "name": "Yara Khan",
      "username": "yara_khan",
      "about": "Fitness coach and lifestyle blogger.",
      "followers": 1500,
      "rank": 7,
      "image_url": "https://randomuser.me/api/portraits/women/13.jpg"
    },
    {
      "name": "Zoe Brooks",
      "username": "zoe_brooks",
      "about": "Artist and illustrator.",
      "followers": 2300,
      "rank": 2,
      "image_url": "https://randomuser.me/api/portraits/women/14.jpg"
    },
    {
      "name": "Adam West",
      "username": "adam_west",
      "about": "Actor and filmmaker.",
      "followers": 1700,
      "rank": 6,
      "image_url": "https://randomuser.me/api/portraits/men/13.jpg"
    },
    {
      "name": "Bella Cruz",
      "username": "bella_cruz",
      "about": "Fashion blogger and influencer.",
      "followers": 2600,
      "rank": 1,
      "image_url": "https://randomuser.me/api/portraits/women/15.jpg"
    },
    {
      "name": "Carlos Diaz",
      "username": "carlos_diaz",
      "about": "Tech entrepreneur and mentor.",
      "followers": 1300,
      "rank": 9,
      "image_url": "https://randomuser.me/api/portraits/men/14.jpg"
    },
]