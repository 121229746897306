import React, { createContext, useState } from 'react';

const FileContext = createContext();

const FileContextProvider = ({ children }) => {
	const [fileURL, setFileURL] = useState('blob:http://localhost:3000/a416087e-934d-4e0e-87b2-d178f0910169');
	return (
		<FileContext.Provider value={{ fileURL, setFileURL }}>
			{children}
		</FileContext.Provider>
	);
};

export { FileContext, FileContextProvider };