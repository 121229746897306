import { Container, Text } from '@chakra-ui/react'
import React from 'react'
import UploadAudio from '../../Components/Audio/UploadAudioFile'
import AudioWaveform from '../../Components/Audio/AudioWaveForm'
import MyLibrary from '../../Components/MyLibrary/MyLibrary'

const DashboardTrending = () => {
  return (
    <Container maxW={'none'}
    color={"var(--primary-text-color)"} p={3}
    rounded="md">
      <MyLibrary/>
      {/* <UploadAudio/> */}
      
      
    </Container>
  )
}

export default DashboardTrending
