import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react"
import UploadAudio from "../../Audio/UploadAudioFile"
import { useEffect, useState } from "react"
import useUploadData from "../../../hooks/useUploadData";
import { useToastVisibility } from "../../../context/Toast/ToastModule";

function UploadMusicModal({ isOpen, onClose }) {
    const { uploadData,isLoading,error ,success} = useUploadData();
    const [allData, setAllData] = useState({})
    const toast = useToast()

    const getDataFromChild = (data)=>{
        setAllData(data)
    }

    useEffect(()=>{
      console.log(isLoading,error)
      if(success){
        onClose()
        toast({
          title: 'Track Uploaded Successfully!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      }
      if(error){
          // onClose()
          toast({
            title: 'Oops! Something Went Wrong!',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
      }
    },[success,error])

    const uploadAudio = ()=>{
        if(!allData?.file || !allData?.songName || !allData?.user || !allData?.image){
            toast({
                title: 'Fill All The Details',
                status: 'error',
                duration: 3000,
                isClosable: true,
              })
              return 
        }
        uploadData(allData?.file,allData?.songName,allData?.user,allData?.image)
    }

    const checkIfDisabled = ()=>{
        if(!allData?.file || !allData?.songName || !allData?.user || !allData?.image){
            return true
        }
        return false
    }

    return (
      <>
        <Modal isCentered isOpen={isOpen} size={'xl'} onClose={onClose}>
            <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
            />
          <ModalContent>
            <ModalHeader>Upload File</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <UploadAudio getDataFromChild={getDataFromChild} isLoading={isLoading} error={error}/>
            </ModalBody>
  
            <ModalFooter>
                <Button disabled={checkIfDisabled} isLoading={isLoading} onClick={uploadAudio}>Upload</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
export default UploadMusicModal
