"use client";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  HStack,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  RadioGroup,
  Radio,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  Tooltip,
  SliderThumb,
  Box,
} from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import React, { useRef, useState } from "react";

export default function ProfileAuth({ formValues, setFormValues }) {
  const [sliderValue, setSliderValue] = React.useState(25);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [avatarSrc, setAvatarSrc] = useState("https://bit.ly/sage-adebayo");
  const fileInputRef = useRef(null);

  const handleAvatarBadgeClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarSrc(reader.result);
        setFormValues({...formValues , profilePicURL:reader.result})
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <Flex align={"center"} justify={"center"} w="100%" maxW={"100%"}>
      <Stack
        spacing={4}
        w={"full"}
        maxW={"100%"}
        rounded={"xl"}
        boxShadow={"lg"}
      >
        <FormControl id="userName">
          <Stack
            direction={["column", "row"]}
            justifyContent={"center"}
            spacing={6}
          >
            <Center>
              <Avatar size="xl" src={avatarSrc}>
                <AvatarBadge
                  as={IconButton}
                  size="sm"
                  rounded="full"
                  top="-10px"
                  colorscheme="red"
                  aria-label="remove Image"
                  onClick={handleAvatarBadgeClick} 
                  icon={<SmallCloseIcon />}
                />
              </Avatar>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={handleFileChange} // Handle file selection
                accept="image/*" // Accept only image files
              />
            </Center>
            {/* <Center w="full">
              <Button w="full">Change Icon</Button>
            </Center> */}
          </Stack>
        </FormControl>
        <FormControl id="email">
          <FormLabel>Gender</FormLabel>
          <RadioGroup>
            <Stack direction="row">
              <Radio
                value={"male"}
                onChange={(e) =>
                  setFormValues({ ...formValues, gender: e.target.value })
                }
              >
                Male
              </Radio>
              <Radio
                value={"female"}
                onChange={(e) =>
                  setFormValues({ ...formValues, gender: e.target.value })
                }
              >
                Female
              </Radio>
              <Radio
                value={"other"}
                onChange={(e) =>
                  setFormValues({ ...formValues, gender: e.target.value })
                }
              >
                Other
              </Radio>
            </Stack>
          </RadioGroup>
        </FormControl>

        <FormControl id="email">
          <FormLabel>Age</FormLabel>
          {/* <Input
            placeholder="your-email@example.com"
            _placeholder={{ color: 'gray.500' }}
            type="date"
            value={formValues.dob}
            onChange={(e) => setFormValues({ ...formValues, dob: e.target.value })}
          /> */}
          <Flex gap={7}>
            <Slider
              id="slider"
              defaultValue={25}
              min={0}
              max={100}
              colorscheme="green"
              onChange={(v) => {
                setFormValues({ ...formValues, age: v });
                setSliderValue(v);
              }}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <Tooltip
                hasArrow
                bg="green"
                color="white"
                placement="top"
                isOpen={showTooltip}
                label={`${sliderValue}`}
              >
                <SliderThumb />
              </Tooltip>
            </Slider>
            <Button height={"20px"}>{sliderValue}</Button>
          </Flex>
        </FormControl>
        <FormControl id="password" isRequired>
          <FormLabel>Mobile Number</FormLabel>
          <Input
            placeholder="+91_"
            _placeholder={{ color: "gray.500" }}
            type="number"
            value={formValues.mobileNumber}
            onChange={(e) =>
              setFormValues({ ...formValues, mobileNumber: e.target.value })
            }
          />
        </FormControl>
      </Stack>
    </Flex>
  );
}
