import { Button, Modal, ModalBody, Text, ModalOverlay, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, useDisclosure, Stack, Flex, FormControl, FormLabel, Input, Heading, Checkbox, Image, Center, Alert, AlertIcon, AlertDescription, AlertTitle } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import Promol from "../../../assets/Promol.png"
import ButtonCuts from "../../Button/ButtonCuts"
import { FcGoogle } from "react-icons/fc"
import { useFirebase } from "../../../context/firebase"
import loginImg from "../../../assets/login.png"
import { useModalVisibility } from "../../../context/ModalToast/useModalToast"
import useLogin from "../../../hooks/useLogin"
import useAuthStore from "../../../store/authStore"
import GoogleAuth from "./GoogleAuth"

export function LogInModal() {
    const {loading, error, login} = useLogin()
    const modal = useModalVisibility()
    


    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
        />
    )

    const [form , setFormData] = useState({
        email:'',
        password:''
    })

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...form,
          [name]: value,
        });
      };

      const handleClickOfSubmit = ()=>{
        login(form)
        
      }

      useEffect(()=>{
        if(!loading){
            modal.onLoginClose()
        }
    },[loading])


    return (
        <>
            <Modal isCentered isOpen={modal.isLoginOpen} onClose={modal.onLoginClose} bg='var(--primary-bg-color)'>
                <OverlayOne />
                <ModalContent bg='var(--primary-bg-color)' maxW={{ base: "90%", md: "50%" }} p={3} color="var(--primary-text-color)">
                    <ModalHeader p={0}><Image h="30px" src={Promol} alt="Logo" /></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack direction={{ base: 'column', md: 'row' }}>
                            <Flex display={{base:'none',md:'flex'}} width={{ base: "100%", md: "50%" }} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                                <Image w="80%" height={"80%"} src={loginImg} alt='Logo'/>
                            </Flex>
                            <Flex width={{ base: "100%", md: "50%" }} alignItems={"center"}>
                                <Stack spacing={4} w={'full'} maxW={'md'}>
                                    <Heading fontSize={'2xl'}>Log in to your account</Heading>
                                    <FormControl id="email">
                                        <FormLabel>Email address</FormLabel>
                                        <Input type="email" name="email" value={form.email} onChange={handleInputChange}/>
                                    </FormControl>
                                    <FormControl id="password">
                                        <FormLabel>Password</FormLabel>
                                        <Input type="password"  name="password" value={form.password} onChange={handleInputChange} />
                                    </FormControl>
                                    {error && <Alert status="error">
                                            <AlertIcon/>
                                            <AlertTitle>Error</AlertTitle>
                                            <AlertDescription>{error.message}</AlertDescription>
                                        </Alert>}
                                    <Stack spacing={6}>
                                        <ButtonCuts text={"Sign In"} isLoading={loading} variant={'solid'} onClick={handleClickOfSubmit}>
                                            Sign in
                                        </ButtonCuts>
                                        <GoogleAuth prefix='Log in'/>
                                    </Stack>
                                </Stack>
                            </Flex>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Flex width={{ base: "100%", md: "50%" }}>

                        </Flex>
                        <Flex width={{ base: "100%", md: "50%" }}>

                        </Flex>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}