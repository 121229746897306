// RotatingCDIcon.js
import React from 'react';
import { GiCompactDisc } from "react-icons/gi";
import './RotatingCDIcon.css'; 

const RotatingCDIcon = () => {
    return (
        // <AiOutlineCd  />
        <GiCompactDisc className="rotating-icon"/>
    );
};

export default RotatingCDIcon;
