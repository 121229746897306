import { Box, Flex, Image, Text, IconButton, Avatar, useBreakpointValue } from '@chakra-ui/react';
import React, { useContext, memo } from 'react';
import { FiPlay, FiMoreVertical, FiPause } from 'react-icons/fi';
import { AudioPlayerContext } from '../../../context/Audio/AudioPlayerContext';
import spectrum from "../../../assets/Spectrum.gif";

const SingleMusicListCard = memo(({ songData }) => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const { currentTrack, isPlaying, playTrack, pauseTrack } = useContext(AudioPlayerContext);

    const handleSongClicked = () => {
        if (isPlaying && currentTrack && currentTrack.id === songData.id) {
            pauseTrack();
            return;
        }
        playTrack(songData);
    };

    const isCurrentTrack = currentTrack && currentTrack.id === songData.id;

    return (
        <Flex
            p={!isMobile ? 3 : 0}
            justifyContent="space-between"
            alignItems="center"
            cursor="pointer"
            borderRadius="8px"
            bg={isCurrentTrack && 'var(--light-bg-color)'}
            _hover={{
                backgroundColor: !isMobile && 'var(--light-bg-color)',
            }}
            width="100%"
            onClick={isMobile ? handleSongClicked : null}
        >
            <Flex alignItems="center" gap={4} flex="2">
                <Box position="relative" width={isMobile ? "60px" : "80px"} height={isMobile ? "50px" : "80px"} borderRadius="12px" overflow="hidden">
                    <Image 
                        src={songData.imageUrl} 
                        width="100%" 
                        height="100%" 
                        objectFit="cover" 
                        loading="lazy"
                    />
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        bg="rgba(0, 0, 0, 0.5)"
                        opacity="0"
                        transition="opacity 0.2s"
                        _hover={{
                            opacity: 1,
                        }}
                    >
                        <IconButton
                            icon={isCurrentTrack ? (isPlaying ? <FiPause /> : <FiPlay />) : <FiPlay />}
                            colorScheme="whiteAlpha"
                            color="white"
                            fontSize="24px"
                            variant="unstyled"
                            aria-label="Play song"
                            onClick={handleSongClicked}
                            display="flex"
                            justifyContent="center"
                        />
                    </Box>
                </Box>

                <Box textAlign="start" flex={isMobile && "1"}>
                    <Text fontWeight={600} fontSize={isMobile ? 'md' : "xl"} noOfLines={1}>{songData.trackName}</Text>
                    <Text color="gray.500" fontSize="sm">{songData?.user?.firstName ? songData.user?.firstName : songData.user?.fullName}</Text>
                </Box>
            </Flex>

            {(isCurrentTrack && isPlaying && isMobile) && (
                <Box ml={4} flex={isMobile ? null : "1"} onClick={handleSongClicked}>
                    <Image src={spectrum} height="35px" mixBlendMode="exclusion" />
                </Box>
            )}
            {(isCurrentTrack && !isPlaying && isMobile) && (
                <Box ml={4} flex={isMobile ? null : "1"}>
                    <FiPlay fontSize="20px" variant="unstyled" aria-label="Play song" onClick={handleSongClicked} />
                </Box>
            )}

            <Box ml={4} flex={isMobile ? null : "1"}>
                <Avatar src={songData.user?.profilePicURL} size={isMobile ? "xs" : "md"} />
            </Box>

            <Flex alignItems="center">
                <IconButton
                    icon={<FiMoreVertical />}
                    variant="ghost"
                    aria-label="Options"
                    fontSize="18px"
                    color="gray.500"
                    _hover={{ color: 'gray.700' }}
                />
            </Flex>
        </Flex>
    );
});

export default SingleMusicListCard;
