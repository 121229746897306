import React, { useState, useEffect, useRef, useContext } from 'react';
import { FileContext } from '../../context/Audio/AudioFileContext';
import useUploadData from '../../hooks/useUploadData';
import useAuthStore from '../../store/authStore';
import AudioWaveform from './AudioWaveForm';
import { Box, Button, Icon, Input, Text, Avatar, AvatarBadge, IconButton, useToast } from '@chakra-ui/react';
import { FiUploadCloud } from 'react-icons/fi';
import { SmallCloseIcon } from '@chakra-ui/icons';

const UploadAudio = ({ getDataFromChild, isLoading, error }) => {
  const inputFile = useRef(null);
  const { uploadData } = useUploadData();
  const { fileURL, setFileURL } = useContext(FileContext);
  const [file, setFile] = useState(null);
  const [songName, setSongName] = useState('');
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(null); // New state for image URL
  const user = useAuthStore((state) => state.user);
  const MAX_FILE_SIZE_SONG = 5 * 1024 * 1024;
  const MAX_FILE_SIZE_Image = 2 * 1024 * 1024;
  const toast = useToast()
  useEffect(() => {
    if (file) {
      setFileURL(file);
    }
    sendDataToParent();
  }, [file, songName, image]);

  useEffect(() => {
    if (image) {
      const url = URL.createObjectURL(image);
      setImageURL(url);


      return () => URL.revokeObjectURL(url);
    }
  }, [image]);

  const sendDataToParent = () => {
    getDataFromChild({
      file,
      image,
      songName,
      user,
    });
  };

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile.size > MAX_FILE_SIZE_SONG) {
      toast({
        title: 'File Size Must Be Lower than 5MB',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } else {
      setFile(selectedFile);
    }
  };

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile.size > MAX_FILE_SIZE_Image) {
      toast({
        title: 'File Size Must Be Lower than 2MB',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } else {
      setImage(selectedFile);
    }
  };

  return (
    <Box w="full" p={6} display="flex" flexDirection="column" alignItems="center">
      {!file ? (
        <Box
          w="full"
          p={6}
          border="2px dashed"
          borderColor="gray.300"
          borderRadius="md"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bg="gray.50"
          _hover={{ borderColor: "gray.400", bg: "gray.100" }}
          position="relative"
        >
          <Icon as={FiUploadCloud} w={12} h={12} color="gray.400" />
          <Text mt={4} fontSize="lg" color="gray.600">
            Drag & Drop your file here
          </Text>
          <Text fontSize="sm" color="gray.500" mt={2}>
            or click to browse your files
          </Text>
          <Input
            type="file"
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            opacity="0"
            accept="audio/*"
            cursor="pointer"
            onChange={handleFileUpload}
          />
        </Box>
      ) : (
        <Box w="full" p={6} borderRadius="md" bg="gray.100" display="flex" flexDirection="column" alignItems="center">
          <AudioWaveform fileURL={URL.createObjectURL(file)} />
          <Text mt={2} fontSize="md" color="gray.600">
            Add Song Information:
          </Text>
          {!image ? (
            <Box
              w="80%"
              mt={4}
              p={6}
              border="2px dashed"
              borderColor="gray.300"
              borderRadius="md"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              bg="gray.50"
              _hover={{ borderColor: "gray.400", bg: "gray.100" }}
              position="relative"
            >
              <Icon as={FiUploadCloud} w={8} h={8} color="gray.400" />
              <Text mt={2} fontSize="sm" color="gray.600">
                Upload song image
              </Text>
              <Input
                type="file"
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                opacity="0"
                accept="image/*"
                cursor="pointer"
                onChange={handleImageUpload}
              />
            </Box>
          ) : (
            <Box mt={4} display="flex" flexDirection="column" alignItems="center" width="100%" justifyContent="space-evenly">
              <Avatar size="xl" src={imageURL} width="90px" height="90px" borderRadius="20px">
                <AvatarBadge
                  as={IconButton}
                  size="sm"
                  rounded="full"
                  bg="white"
                  color="red"
                  top="-15px"
                  aria-label="remove Image"
                  onClick={() => {
                    setImage(null);
                    setImageURL(null);
                  }}
                  icon={<SmallCloseIcon />}
                />
              </Avatar>
              <Input
                value={songName}
                onChange={(e) => setSongName(e.target.value)}
                mt={4}
                width="100%"
                color={"var(--primary-text-color)"}
                placeholder="Enter song name"
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default UploadAudio;
