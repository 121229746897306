import { Button, Center, Flex, Image, Text } from "@chakra-ui/react";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import useShowToast from "../../../hooks/useShowToast";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { FcGoogle } from "react-icons/fc";
import useAuthStore from "../../../store/authStore";
import { auth , firestore } from "../../../firebase/firebase";
import { useModalVisibility } from "../../../context/ModalToast/useModalToast";

const GoogleAuth = ({ prefix }) => {
	const [signInWithGoogle, ,loading , error] = useSignInWithGoogle(auth);
	const showToast = useShowToast();
	const loginUser = useAuthStore((state) => state.login);
    const modal = useModalVisibility()

	const handleGoogleAuth = async () => {
		try {
			const newUser = await signInWithGoogle();
			if (!newUser && error) {
				showToast("Error", error.message, "error");
				return;
			}
			const userRef = doc(firestore, "users", newUser.user.uid);
			const userSnap = await getDoc(userRef);

			if (userSnap.exists()) {
				// login
				const userDoc = userSnap.data();
				localStorage.setItem("user-info", JSON.stringify(userDoc));
				loginUser(userDoc);
                modal.onLoginClose()
                modal.onSignupClose()
			} else {
				const userDoc = {
					uid: newUser.user.uid,
					email: newUser.user.email,
					username: newUser.user.email.split("@")[0],
					fullName: newUser.user.displayName,
					bio: "",
					profilePicURL: newUser.user.photoURL,
					followers: [],
					following: [],
					posts: [],
					createdAt: Date.now(),
				};
				await setDoc(doc(firestore, "users", newUser.user.uid), userDoc);
				localStorage.setItem("user-info", JSON.stringify(userDoc));
				loginUser(userDoc);
                modal.onSignupClose();
                modal.onLoginClose()
                
			}
		} catch (error) {
			showToast("Error", error.message, "error");
		}
	};

	return (
        <Button w={'full'} isLoading={loading} variant={'solid'} onClick={handleGoogleAuth} leftIcon={<FcGoogle />}>
            <Center>
                <Text>{prefix} with Google</Text>
            </Center>
        </Button>
	);
};

export default GoogleAuth;