import React, { useContext } from 'react';
import useSongsData from '../../../hooks/useGetSongsData';
import { AudioPlayerContext } from '../../../context/Audio/AudioPlayerContext';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import SingleMuiscListCard from './SingleMuiscListCard';
import { PageLayoutSpinner } from '../../../Layout/PageLayout';

const SongList = React.memo(() => {
  const { songs, loading, error } = useSongsData();
  const { playTrack } = useContext(AudioPlayerContext);
  const isMobile = useBreakpointValue({ base: true, md: false });
  if (loading) return (
    <PageLayoutSpinner h={'70vh'} w={'100%'} text={'Loading Data...'} />
  );
  if (error) return <p>Error loading songs: {error.message}</p>;

  return (
    <Box mt={3} display={'flex'} flexDirection={'column'} gap={3} mb={isMobile ? "80px": '70px'}>
      {songs.map((song) => (
        <Box key={song.id}>
          <SingleMuiscListCard songData={song} onPlay={playTrack} />
        </Box>
      ))}
    </Box>
  );
});

export default SongList;
