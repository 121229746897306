'use client'

import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
} from '@chakra-ui/react'
import AOS from 'aos';
import 'aos/dist/aos.css'; 

import guitarguys from "../../assets/img-guitar.png"
AOS.init({
    duration: 700,
})

export default function OurFeatures() {
  return (
    <Container  maxWidth={'1700px'} py={12} mt={{base:5,md:32}}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} margin={"auto"} templateColumns={{ base: '1fr', md: '30% 60%' }}>
      <Flex data-aos="fade-up">
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={
                guitarguys
            }
            objectFit={'cover'}
          />
        </Flex>
        <Stack spacing={4} justifyContent={"center"} data-aos="zoom-in">
          <Heading color="var(--highlighted-color)" whiteSpace={{ base: 'normal', sm: 'normal', lg: 'nowrap' }} textOverflow={"ellipsis"} overflow={"hidden"} fontSize={{base:"2xl",md:"5xl"}}>Creative Hub for Brand Amplification</Heading>
          <Text color="var(--primary-text-color)" fontSize={{base:"14px",md:'20px'}} textAlign={"start"}>
          Welcome to 'The Promo Catalyst,' where we redefine storytelling through the fusion of captivating music and dynamic animation. Our mission is simple: to empower your brand with the tools it needs to stand out in today's crowded digital landscape. Picture this: Your brand's narrative unfolds with each beat, accompanied by stunning visuals that captivate your audience and leave a lasting impression. At 'The Promo Catalyst,' we don't just create marketing content; we craft immersive experiences that elevate your brand above the noise. Let us be your guide as we embark on a journey to unleash the full potential of your brand's story.
          </Text>
        </Stack>

      </SimpleGrid>
    </Container>
  )
}