import { Button, Modal, ModalBody, Text, ModalOverlay, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, useDisclosure, Stack, Flex, FormControl, FormLabel, Input, Heading, Checkbox, Image, Alert, AlertIcon } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import Promol from "../../../assets/Promol.png"
import { useFirebase } from "../../../context/firebase";
import { useModalVisibility } from "../../../context/ModalToast/useModalToast";
import Multistep from "./MultiStepsForm";
import { useToastVisibility } from "../../../context/Toast/ToastModule";
import useSignUpWithEmailAndPassword from "../../../hooks/useSIgnInWithEmailPassword";
import useAuthStore from "../../../store/authStore";

export function SignUpModal({ isOpen, onClose }) {
    const toast = useToastVisibility()
    const modal = useModalVisibility()
    const { loading, error, signup } = useSignUpWithEmailAndPassword();
    const [localLoader , setLocalLoader] = useState(false)
    const user = useAuthStore((state) => state.user)
    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
        />
    )

    const handleClickOfSubmit = (userdata)=>{
        signup(userdata)
    }

    useEffect(()=>{
        if(!loading){
            modal.onSignupClose()
        }
    },[loading,user])


    return (
        <>
            <Modal isCentered isOpen={modal.isSignupOpen} onClose={modal.onSignupClose} bg='var(--primary-bg-color)'>
                <OverlayOne />
                <ModalContent bg='var(--primary-bg-color)' borderColor={"white"} maxW={{base:"97%",md:"50%"}} color="var(--primary-text-color)">
                <ModalHeader><Image h="30px" src={Promol} alt="Logo" /></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Multistep handleClickOfSubmit={handleClickOfSubmit} loading={loading} error={error}/>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}