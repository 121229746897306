import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom'
import Main from './Routes/Main';
import { auth } from '../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import SidebarDashbaord from '../Components/Sidebar/Sidebar';
import DocumentPage from './Routes/HowItWorks';

const AllRoutes = () => {

  // const authUser = useAuthStore((state) => state.user);
  const [authUser] = useAuthState(auth);
  return (
    <Routes>
        <Route path="/" element={<Main/>}></Route>
        <Route path="/dashboard/*" element={authUser ? <SidebarDashbaord/> : <Navigate to="/"/> }></Route>
        <Route path="/about" element={<DocumentPage/> }></Route>
    </Routes>
  )
}

export default AllRoutes
