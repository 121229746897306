import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import MobileScreenPlayer from "../../Audio/AudioPlayer/MobileScreenPlayer"

export default function AudioModal({isOpen  , onClose}) {
    // const { isOpen, onOpen, onClose } = useDisclosure()
    return (
      <>
        {/* <Button onClick={onOpen}>Open Modal</Button> */}
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          motionPreset='slideInBottom'
          size={'full'}
          padding={0}
        >
          <ModalOverlay/>
          <ModalContent>
            <ModalCloseButton />
            <ModalBody padding={0}>
              {/* <Lorem count={2} /> */}
              <MobileScreenPlayer/>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }