import { useToast } from "@chakra-ui/react";
import { useContext , createContext, useState} from "react";


const ToastContext = createContext(null)


export const ToastViewProvider = (props) => {
    const toast = useToast()

    const [toastStatus , setToastStatus] = useState({
        title:'',
        status:'',
        isClosable:false
    });


    const handleSetToastStatus = (msg , status ,isClose)=>{
      console.log(msg)
        toast({
            title: `${msg}`,
            status: status,
            isClosable: isClose,
          })
    }

    return (
      <ToastContext.Provider value={{toastStatus,handleSetToastStatus}}>
        {props.children}
      </ToastContext.Provider>
    )
}

export const useToastVisibility = ()=> useContext(ToastContext)