import React, { useState } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { RiMenu2Line } from 'react-icons/ri';
import { IoClose } from 'react-icons/io5';

const MotionBox = motion(Box);

const MenuToggle = ({onOpen}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    onOpen()
  };

  return (
    <IconButton
        display={{ base: 'flex', md: 'none' }}
      onClick={toggleMenu}
      color={'var(--highlighted-color)'}
      fontSize={'22px'}
      icon={
        <MotionBox
          initial={{ rotate: 0 }}
          animate={{ rotate: isOpen ? 360 : 0 }}
          transition={{ duration: 0.2 }}
        >
          {/* {isOpen ? <IoClose fontSize={'32px'}/> : <RiMenu2Line fontSize={'32px'} />} */}
          <RiMenu2Line fontSize={'32px'} />
        </MotionBox>
      }
      aria-label="Toggle Menu"
      bg="transparent"
      _hover={{ bg: 'transparent' }}
    />
  );
};

export default MenuToggle;
