import { FormControl, FormLabel, Grid, Input, Select } from '@chakra-ui/react'
import useAuthStore from '../../store/authStore'
import { useEffect } from 'react'

function AccountSettings() {

  const user = useAuthStore((state) => state.user)
  const name = user?.fullName || user?.firstName
  const total = name?.split(" ");


  

  return (
    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
      gap={6}
    >
      <FormControl id="firstName">
        <FormLabel>First Name</FormLabel>
        <Input focusBorderColor="brand.blue" type="text" placeholder="Tim" defaultValue={total?.[0]}/>
      </FormControl>
      <FormControl id="lastName">
        <FormLabel>Last Name</FormLabel>
        <Input focusBorderColor="brand.blue" type="text" placeholder="Cook" defaultValue={total?.[1]}/>
      </FormControl>
      <FormControl id="phoneNumber">
        <FormLabel>Phone Number</FormLabel>
        <Input
          focusBorderColor="brand.blue"
          type="tel"
          placeholder="(408) 996–1010"
          defaultValue={user?.mobileNumber}
        />
      </FormControl>
      <FormControl id="emailAddress">
        <FormLabel>Email Address</FormLabel>
        <Input
          focusBorderColor="brand.blue"
          type="email"
          placeholder="tcook@apple.com"
          defaultValue={user?.email}
          disabled={true}
        />
      </FormControl>
      <FormControl id="website">
        <FormLabel>Website/Portfolio</FormLabel>
        <Input
          focusBorderColor="brand.blue"
          type="text"
          placeholder="www.promocatalyst.online"
          defaultValue={user?.website}
        />
      </FormControl>
      <FormControl id="age">
        <FormLabel>Date Of Birth</FormLabel>
        <Input
          focusBorderColor="brand.blue"
          type="date"
          placeholder="12/06/1999"
          defaultValue={user?.age}
        />
      </FormControl>
      <FormControl id="city">
        <FormLabel>City</FormLabel>
        <Select focusBorderColor="brand.blue" placeholder="Select city">
          <option value="california">California</option>
          <option value="washington">Washington</option>
          <option value="toronto">Toronto</option>
          <option value="newyork">
            New York
          </option>
          <option value="london">London</option>
          <option value="netherland">Netherland</option>
          <option value="poland">Poland</option>
        </Select>
      </FormControl>
      <FormControl id="country">
        <FormLabel>Country</FormLabel>
        <Select focusBorderColor="brand.blue" placeholder="Select country">
          <option value="america">
            America
          </option>
          <option value="england">England</option>
          <option value="poland">Poland</option>
        </Select>
      </FormControl>
    </Grid>
  )
}

export default AccountSettings
