import { Box, Flex, Skeleton, SkeletonCircle, SkeletonText, Stack } from '@chakra-ui/react'
import React from 'react';
import utilityStore from '../store/utilStore';


export const SkeletonLayout = ({ children }) => {
    const skelton = utilityStore((state) =>  state.skeleton)
	if (skelton) return <SkeletonBody />;

	return (
		<Flex>
			<Box flex={1} w={{ base: "calc(100% - 70px)", md: "calc(100% - 240px)" }}  mx={"auto"}>
				{children}
			</Box>
		</Flex>
	);
};



const SkeletonBody = () => {
  return (
        <Stack bg={"var(--primary-bg-color)"} height={"100vh"}>
            <Box padding='6' boxShadow='lg'>
                <Flex w="90vw" justifyContent={"space-between"}>
                    <Skeleton height={"30px"}/>
                    <SkeletonCircle size='20' />
                </Flex>
                <SkeletonText mt='20' noOfLines={6} spacing='4' skeletonHeight='4' />
                <SkeletonText mt='20' noOfLines={6} spacing='4' skeletonHeight='4' />
                <SkeletonText mt='20' noOfLines={6} spacing='4' skeletonHeight='3' />
            </Box>
        </Stack>
  )
}

export default SkeletonBody
