import React, { useState, useEffect, useContext, useRef } from "react";
// import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
// import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import { FileContext } from "../../context/Audio/AudioFileContext";
import wavesurfer from "wavesurfer.js";
import "./Audio.css";
import { FaPause, FaPlay, FaVolumeOff } from "react-icons/fa";
import { MdGraphicEq, MdOutlineReplay } from "react-icons/md";
import { FaScissors, FaVolumeXmark } from "react-icons/fa6";
import {
  Box,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";

const AudioWaveform = ({ fileURL }) => {
  const wavesurferRef = useRef(null);
  const timelineRef = useRef(null);

  const [wavesurferObj, setWavesurferObj] = useState(null);

  const [playing, setPlaying] = useState(true);
  const [volume, setVolume] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (wavesurferRef.current && !wavesurferObj) {
      setWavesurferObj(
        wavesurfer.create({
          container: "#waveform",
          scrollParent: true,
          autoCenter: true,
          cursorColor: "transparent",
          loopSelection: true,
          waveColor: "#c0cabe",
          progressColor: "#6DE754",
          responsive: true,
          url: fileURL,
          normalize:true,
          barWidth:2,
          height:50
        })
      );
    }
  }, [wavesurferRef, wavesurferObj]);


  useEffect(() => {
    if (fileURL && wavesurferObj) {
      wavesurferObj.load(fileURL);
    }
  }, [fileURL, wavesurferObj]);

  const handlePlayPause = (e) => {
    wavesurferObj.playPause();
    setPlaying(!playing);
  };

  const handleReload = (e) => {

    wavesurferObj.stop();
    wavesurferObj.play();
    setPlaying(true); 
  };

  const handleVolumeSlider = (e) => {
    setVolume(e.target.value);
  };

  const handleZoomSlider = (e) => {
    setZoom(e.target.value);
  };

  return (
    <section className="waveform-container" style={{width:"100%",display:"flex",gap:"20px",alignItems:"center",justifyContent:"center"}}>
        <button
            title="play/pause"
            className="controls"
            onClick={handlePlayPause}
            style={{'color':'var(--highlighted-color'}}
          >
            {playing ? <FaPlay /> : <FaPause />}
          </button>
      <div ref={wavesurferRef} id="waveform" style={{width:"100%"}}/>
    </section>
  );
};

export default AudioWaveform;
