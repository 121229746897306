import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';

const DocumentPage = () => {
  return (
    <Box
      minH="100vh"
      bg="var(--primary-bg-color)"
      color={"var(--primary-text-color)"}
      p={8}
    >
      <VStack spacing={6} maxW="800px" mx="auto">
        <Heading>How It Works: The Promo Catalyst</Heading>
        <Text fontSize="lg">
          Welcome to <strong>The Promo Catalyst</strong>, where we redefine storytelling through captivating music and dynamic animation. Our mission is to empower your brand with innovative tools to stand out in today’s crowded digital landscape.
        </Text>
        <Text fontSize="lg" color="var(--highlighted-color-light)">
          <strong>1. Getting Started</strong>
        </Text>
        <Text fontSize="md">
          <strong>1.1. Sign Up and Log In</strong>
        </Text>
        <Text>
          - <strong>Sign Up</strong>: Create an account by providing your email address, setting a password, and entering basic company information.
          <br />
          - <strong>Log In</strong>: Access your account using your credentials to manage and create promotional content.
        </Text>
        <Text fontSize="md" color="gray.600">
          <strong>1.2. Dashboard Overview</strong>
        </Text>
        <Text>
          Once logged in, you will be directed to your dashboard. Key sections include:
          <br />
          - <strong>Profile Section</strong>: Manage your account details and preferences.
          <br />
          - <strong>Explore Catalyst Artists</strong>: Discover and connect with artists who can enhance your brand’s story with their music.
          <br />
          - <strong>Trending Section</strong>: View the latest trends in music and animation for inspiration.
        </Text>
        <Text fontSize="lg" color="var(--highlighted-color-light)">
          <strong>2. Creating and Managing Projects</strong>
        </Text>
        <Text fontSize="md" color="gray.600">
          <strong>2.1. Start a New Project</strong>
        </Text>
        <Text>
          - <strong>Project Name</strong>: Give your project a unique name.
          <br />
          - <strong>Brand Narrative</strong>: Define the story or message you want to convey.
          <br />
          - <strong>Music and Animation</strong>: Choose from our library or collaborate with artists.
        </Text>
        <Text fontSize="md" color="gray.600">
          <strong>2.2. Project Management</strong>
        </Text>
        <Text>
          - <strong>Preview</strong>: Review your project before finalizing.
          <br />
          - <strong>Edit</strong>: Make adjustments as needed.
          <br />
          - <strong>Publish</strong>: Finalize and share your project.
        </Text>
        <Text fontSize="lg" color="var(--highlighted-color-light)">
          <strong>3. Exploring Content</strong>
        </Text>
        <Text fontSize="md" color="gray.600">
          <strong>3.1. Explore Catalyst Artists</strong>
        </Text>
        <Text>
          - <strong>Discover Artists</strong>: Browse through our selection.
          <br />
          - <strong>Collaborate</strong>: Reach out to artists for custom content.
        </Text>
        <Text fontSize="md" color="gray.600">
          <strong>3.2. Trending Content</strong>
        </Text>
        <Text>
          - <strong>View Trends</strong>: Stay updated with the latest trends in music and animation.
        </Text>
        <Text fontSize="lg" color="var(--highlighted-color-light)">
          <strong>4. Support and Resources</strong>
        </Text>
        <Text fontSize="md" color="gray.600">
          <strong>4.1. Help Center</strong>
        </Text>
        <Text>
          - <strong>FAQs</strong>: Find answers to common questions.
          <br />
          - <strong>Guides</strong>: Access tutorials and guides.
        </Text>
        <Text fontSize="md" color="gray.600">
          <strong>4.2. Contact Support</strong>
        </Text>
        <Text>
          - <strong>Support Tickets</strong>: Submit tickets for assistance.
          <br />
          - <strong>Live Chat</strong>: Use live chat for real-time help.
        </Text>
        <Text fontSize="lg" color="gray.700">
          <strong>Conclusion</strong>
        </Text>
        <Text>
          <strong>The Promo Catalyst</strong> is designed to enhance your brand’s storytelling through the fusion of music and animation. Use this guide to navigate our platform effectively and create impactful promotional content.
        </Text>
      </VStack>
    </Box>
  );
};

export default DocumentPage;
