import { Container } from '@chakra-ui/layout'
import Content from './Content/Content'
import Sidebar from './SidebarProfile/Sidebar'

export default function MainProfileComp() {
  return (
    <Container display={{ base: 'block', md: 'flex' }} bg="var(--primary-bg-color)" maxW="none" p={{base:4,md:10}}>
      <Sidebar />
      <Content />
    </Container>
  )
}
