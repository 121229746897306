import { Box, Container, Text } from '@chakra-ui/react'
import React from 'react'
import CoverProfile from '../../Components/Cover';
import MainProfileComp from "../../Components/MainProfileComp"

const DashboardProfile = () => {
  return (
    <>
      <Box>
        <CoverProfile />
        <MainProfileComp />
      </Box>
      
    </>
  )
}

export default DashboardProfile
