import { useContext , createContext, useState} from "react";


const ToastModalContext = createContext(null)


export const ToastModalProvider = (props) => {

    const [isLoginOpen , setIsLoginOpen] = useState(false);
    const [isSignupOpen , setSignupOpen] = useState(false);

    const onLoginClose = ()=>{
      setIsLoginOpen(false)
    }

    const toLoginOpen = ()=>{
      setIsLoginOpen(true)
    }

    const onSignupClose = ()=>{
      setSignupOpen(false)
    }

    const onSignupOpen = ()=>{
      setSignupOpen(true)
    }


    return (
      <ToastModalContext.Provider value={{onLoginClose , toLoginOpen , isLoginOpen , onSignupClose , onSignupOpen , isSignupOpen}}>
        {props.children}
      </ToastModalContext.Provider>
    )
}

export const useModalVisibility = ()=> useContext(ToastModalContext)