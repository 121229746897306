import { initializeApp } from "firebase/app";

import React, { useContext } from 'react'
import {createContext} from "react"
import { getAuth, signInWithPopup, GoogleAuthProvider , createUserWithEmailAndPassword , signInWithEmailAndPassword} from "firebase/auth";
import { collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const provider = new GoogleAuthProvider();

const FirebaseContext = createContext(null)


const firebaseConfig = {
  apiKey: "AIzaSyBNLWcPlVFu4Xlz4ABkwyVIhVxiEr9plqM",
  authDomain: "thepromocatalyst.firebaseapp.com",
  projectId: "thepromocatalyst",
  storageBucket: "thepromocatalyst.appspot.com",
  messagingSenderId: "569162333309",
  appId: "1:569162333309:web:5a3a8bb968469e0d2c8514",
  measurementId: "G-J5BW63S4H2"
};



const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app)

const loginWithGmailAuth = ()=>{
  return signInWithPopup(auth, provider)
}



const createNewUser = (userData)=>{
  return createUserWithEmailAndPassword(auth, userData?.email, userData?.password)

}


const saveUserInfoToDataBase = async (inputs)=>{
    const usersRef = collection(firestore, "users");
    const q = query(usersRef, where("username", "==", inputs.email));
		const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
			console.log("Error", "Username already exists", "error");
			return new Error('Username already exists')
		}
    try {
			const newUser = await createUserWithEmailAndPassword(auth , inputs.email, inputs.password);
			if (!newUser) {
				console.log("Error","No User Found", "error");
				return new Error('Something Went Wrong')
			}
			if (newUser) {
        console.log(newUser)
				const userDoc = {
					uid: newUser.user.uid,
					email: inputs.email,
					firstName: inputs.firstName,
					lastName: inputs.lastName,
          mobileNumber:inputs.mobileNumber,
          gender:inputs.gender,
          dob:inputs.dob,
					profilePicURL: "",
					posts: [],
					createdAt: Date.now(),
				};
				await setDoc(doc(firestore, "users", newUser.user.uid), userDoc);
				localStorage.setItem("user-info", JSON.stringify(userDoc));
        return true
			}
		} catch (error) {
			console.log("Error", error.message, "error");
      return error
    }
}

const LoginUser = (userData)=>{
  return signInWithEmailAndPassword(auth, userData?.email, userData?.password)

}


const FirebaseProvider = (props) => {
  return (
    <FirebaseContext.Provider value={{loginWithGmailAuth,createNewUser,LoginUser,saveUserInfoToDataBase}}>
      {props.children}
    </FirebaseContext.Provider>
  )
}

export const useFirebase = ()=> useContext(FirebaseContext)

export default FirebaseProvider;