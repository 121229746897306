// theme.js

import { extendTheme } from '@chakra-ui/react';
import { BottomNavigationStyleConfig as BottomNavigation } from 'chakra-ui-bottom-navigation';

const customTheme = extendTheme({
// fonts: {
  //   heading: `'Poppins', sans-serif`,
  //   body: `'Poppins', sans-serif`,
  // },
    colors: {
        primaryBg: '#161815',
        primaryText: '#a4a4a4',
        highlighted: '#6DE754',
        highlightedLight: '#94e187',
        card: '#7a7a7a',
    },
  components: {
    Input: {
      baseStyle: {
        field: {
          borderColor: 'highlighted',
          _hover: {
            borderColor: 'highlighted',
          },
          _focusVisible: {
            borderColor: 'highlighted',
            boxShadow: '0 0 0 1px blue.500',
          },
        },
      },
      sizes: {
        md: {
          field: {
            fontSize: '18px',
            padding: '22px 16px',
          },
        },
        sm:{
          field: {
            fontSize: '18px',
            padding: '18px 14px',
          },
        }
      },
      variants: {
        outline: {
          field: {
            borderColor: 'white',
          },
        },
        filled: {
          field: {
            bg: 'highlighted',
            _hover: {
              bg: 'gray.200',
            },
            _focus: {
              bg: 'white',
            },
          },
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'outline',
      },
    },
    BottomNavigation,
    global: {
      'html, body': {
        fontFamily: 'Roboto, sans-serif',
        color: 'gray.800',
        lineHeight: '1.6',
        fontSize: '16px',
      },
      h1: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '700',
        fontSize: '2rem',
        color: 'teal.600',
      },
      h2: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '1.5rem',
        color: 'teal.500',
      },
      h3: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '500',
        fontSize: '1.25rem',
        color: 'teal.400',
      },
      p: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '1rem',
        color: 'gray.700',
      },
      a: {
        color: 'teal.500',
        _hover: {
          color: 'teal.700',
          textDecoration: 'underline',
        },
      },
    },
  },
});

export default customTheme;