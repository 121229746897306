import { useContext , createContext, useState} from "react";


const AlertModalContext = createContext(null)


export const AlertModalProvider = (props) => {

    const [isAlertOpen , setisAlertOpen] = useState(false);
    const [dataForAlert , setDataForAlert] = useState({
        header:"",
        body:"",
        button1:"",
        button2:""
    })

    const onAlertModalOpen = ()=>{
        setisAlertOpen(true)
    }

    const onAlertModalClose = ()=>{
        setisAlertOpen(false)
    }




    return (
      <AlertModalContext.Provider value={{isAlertOpen,onAlertModalOpen ,onAlertModalClose}}>
        {props.children}
      </AlertModalContext.Provider>
    )
}

export const useAlertModalVisibility = ()=> useContext(AlertModalContext)