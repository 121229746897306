import {
    Box,
    Stack,
    Popover,
    PopoverTrigger,
  } from "@chakra-ui/react";
  import style from "./DesktopNav.module.css"
  import {Link} from "react-router-dom"

  
  const DesktopNav = ({ NAV_ITEMS }) => {

  
    return (
      <Stack direction={"row"} spacing={{ base: 4, md: 4, lg: 7 }}>
        {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <Link
                className={style.link}
                    to={navItem.link}
                >
                  {navItem.label}
                </Link>
              </PopoverTrigger>
            </Popover>
          </Box>
        ))}
      </Stack>
    );
  };
  
  export default DesktopNav;
  