import { Box } from '@chakra-ui/react'

import Actions from './Actions'
import Data from './Data'
import Profile from './Profile'
import useAuthStore from '../../store/authStore'

function Sidebar() {
  const user = useAuthStore((state) => state.user)
  return (
    <Box
      as="aside"
      flex={1}
      mr={{ base: 0, md: 5 }}
      mb={{ base: 5, md: 0 }}
      bg="var(--main-card-color)"
      rounded="md"
      color={"var(--primary-text-color)"}
      boxShadow='rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
      // boxShadow='rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important'
      style={{ transform: 'translateY(-100px)' }}
    >
      <Profile user={user}/>
      <Data  user={user} />
      <Actions  user={user} />
    </Box>
  )
}

export default Sidebar
