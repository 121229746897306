// BottomAudioPlayer.js
import React, { useContext } from "react";
import {
  Box,
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  Image,
  Flex,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  FiPlay,
  FiPause,
  FiSkipForward,
  FiSkipBack,
  FiVolume2,
} from "react-icons/fi";
import { AudioPlayerContext } from "../../../context/Audio/AudioPlayerContext";
import { FaRepeat, FaShuffle } from "react-icons/fa6";
import './player.css'

const BottomAudioPlayer = () => {
  const {
    currentTrack,
    isPlaying,
    playTrack,
    pauseTrack,
    progress,
    seekTrack,
    loading,
    volume,
    setVolume,
  } = useContext(AudioPlayerContext);

  const isMobile = useBreakpointValue({ base: true, md: false });

  if (!currentTrack) return null;

  return (
    <Box
      position="fixed"
      bottom="0"
      width="100%"
      bg="var(--light-bg-color)"
      p={isMobile ? 2 : 2}
      color="white"
      zIndex="1000"
      boxShadow="0px -2px 8px rgba(0, 0, 0, 0.3)"
      display={isMobile ? "none":"flex"}
      flexDirection={isMobile ? "column" : "row"}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center" gap={3} mb={isMobile ? 2 : 0}>
        <Image
          src={currentTrack.imageUrl}
          alt="Track Art"
          boxSize={isMobile ? "50px" : "60px"}
          objectFit="cover"
          borderRadius="10px"
        />
        <Box textAlign="start">
          <Text fontWeight={700} fontSize="md" color="var(--primary-text-color)">
            {currentTrack.trackName}
          </Text>
          <Text color="gray.500" fontSize="sm">
            {currentTrack.artist}
          </Text>
        </Box>
      </Flex>

      <Flex flex={1} justifyContent="center">
        <Flex gap={2}>
          <IconButton
            icon={<FiSkipBack />}
            // onClick={previousTrack}
            variant="ghost"
            fontSize="20px"
            aria-label="Previous Track"
          />
          <IconButton
            icon={isPlaying ? <FiPause /> : <FiPlay />}
            onClick={isPlaying ? pauseTrack : () => playTrack(currentTrack)}
            variant="ghost"
            fontSize="24px"
            aria-label="Play/Pause"
          />
          <IconButton
            icon={<FiSkipForward />}
            // onClick={nextTrack}
            variant="ghost"
            fontSize="20px"
            aria-label="Next Track"
          />
        </Flex>
        <Slider
          value={progress}
          onChange={seekTrack}
          colorScheme="green"
          aria-label="audio-progress"
          w={isMobile ? "90%" : "50%"}
          mx={isMobile ? 2 : 4}
          mt={isMobile ? 2 : 0}
        >
          <SliderTrack bg="gray.600">
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Flex>

      {/* Volume Control */}
      <Flex align="center" gap={10} mt={isMobile ? 2 : 0} display={isMobile ? "none" : "flex"}>
        <Flex align="center" gap={2}>
          <FiVolume2 size="20px" color="var(--primary-text-color)" />
          <Slider
            value={volume * 100} // Convert volume to percentage for the slider
            onChange={(value) => setVolume(value / 100)} // Convert back to decimal for volume
            max={100}
            step={1}
            colorScheme="green"
            aria-label="Volume"
            width={isMobile ? "60px" : "80px"}
          >
            <SliderTrack bg="gray.600">
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Flex>

        <Flex gap={2}>
          <IconButton icon={<FaRepeat />} aria-label="Repeat" variant="ghost" />
          <IconButton icon={<FaShuffle />} aria-label="Shuffle" variant="ghost" />
        </Flex>
      </Flex>

      {loading && (
        <Box position="absolute" top="50%" height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} left="50%" width={'100%'} transform="translate(-50%, -50%)" bg={'var(--light-bg-color)'}>
          <Spinner size="lg" color="green.500" />
        </Box>
      )}
    </Box>
  );
};

export default BottomAudioPlayer;
