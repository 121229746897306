import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBNLWcPlVFu4Xlz4ABkwyVIhVxiEr9plqM",
    authDomain: "thepromocatalyst.firebaseapp.com",
    projectId: "thepromocatalyst",
    storageBucket: "thepromocatalyst.appspot.com",
    messagingSenderId: "569162333309",
    appId: "1:569162333309:web:5a3a8bb968469e0d2c8514",
    measurementId: "G-J5BW63S4H2"
};
  

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { app, auth, firestore, storage };