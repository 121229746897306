"use client";

import { useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  SimpleGrid,
  InputLeftAddon,
  InputGroup,
  FormHelperText,
  InputRightElement,
  Stepper,
  Step,
  StepIndicator,
  StepIcon,
  StepNumber,
  StepTitle,
  StepStatus,
  StepSeparator,
  Divider,
  AbsoluteCenter,
  Alert,
  AlertDescription,
  AlertTitle,
  AlertIcon,
} from "@chakra-ui/react";

import ButtonCuts from "../../Button/ButtonCuts";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import ProfileAuth from "./ProfileAuth";
import ConnectAllIcons from "./ConnectIcons";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import { FcGoogle } from "react-icons/fc";
import GoogleAuth from "./GoogleAuth";

const MotionArrowRightIcon = motion(ArrowRightIcon);
const MotionArrowLeftIcon = motion(ArrowLeftIcon);
const Form1 = ({ formValues, setFormValues ,disabled }) => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(!show);
  };
  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        User Registration
      </Heading>
      <Flex flexDirection={{ base: "column", md: "row" }}>
        <FormControl>
          <FormLabel htmlFor="first-name" fontWeight={"normal"}>
            First name
          </FormLabel>
          <Input
            id="name"
            placeholder="Your Name"
            value={formValues.firstName}
            onChange={(e) =>
              setFormValues({ ...formValues, firstName: e.target.value })
            }
          />
        </FormControl>
      </Flex>
      <FormControl mt="2%" isRequired>
        <FormLabel htmlFor="email" fontWeight={"normal"}>
          Email address
        </FormLabel>
        <Input
          required
          id="email"
          type="email"
          placeholder="Enter Email"
          value={formValues.email}
          onChange={(e) =>
            setFormValues({ ...formValues, email: e.target.value })
          }
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel htmlFor="password" fontWeight={"normal"} mt="1%">
          Password
        </FormLabel>
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            required
            type={show ? "text" : "password"}
            placeholder="Create password"
            value={formValues.password}
            onChange={(e) =>
              setFormValues({ ...formValues, password: e.target.value })
            }
          />
          <InputRightElement
            width="4.5rem"
            h="100%"
            color={"var(--primary-highlighted-color)"}
          >
            {show ? (
              <FaRegEye
                onClick={handleClick}
                color={"var(--highlighted-color)"}
              />
            ) : (
              <FaRegEyeSlash
                color={"var(--highlighted-color)"}
                onClick={handleClick}
              />
            )}
          </InputRightElement>
        </InputGroup>
      </FormControl>
      {disabled && <Alert status="error" mt={3} bg='red'>
            <AlertIcon/>
              <AlertTitle>*</AlertTitle>
              <AlertDescription>{disabled}</AlertDescription>
            </Alert>}
    </>
  );
};

const Form2 = ({ formValues, setFormValues }) => {
  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        User Details
      </Heading>
      <ProfileAuth formValues={formValues} setFormValues={setFormValues} />
    </>
  );
};

const Form3 = ({ formValues, setFormValues }) => {
  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal">
        Social Handles
      </Heading>
      <SimpleGrid>
        <FormControl as={GridItem} colSpan={[3, 2]}>
          <FormLabel>Website</FormLabel>
          <InputGroup size="sm">
            <InputLeftAddon
              bg="gray.50"
              _dark={{
                bg: "gray.800",
              }}
              color="black"
              rounded="md"
            >
              https://
            </InputLeftAddon>
            <Input
              type="tel"
              placeholder="www.example.com"
              rounded="md"
              value={formValues.website}
              onChange={(e) =>
                setFormValues({ ...formValues, website: e.target.value })
              }
            />
          </InputGroup>
        </FormControl>

        <FormControl id="about" mt={6}>
          <FormLabel>About</FormLabel>
          <ConnectAllIcons
            formValues={formValues}
            setFormValues={setFormValues}
          />
          <FormHelperText>
            Link your account to your profile for better reach. URLs are
            hyperlinked.
          </FormHelperText>
        </FormControl>
      </SimpleGrid>
    </>
  );
};

export default function Multistep({ handleClickOfSubmit, loading, error }) {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(33.33);
  const steps = [
    { idx: 1, title: "Basic" },
    { idx: 2, title: "About" },
    { idx: 1, title: "Social" },
  ];
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    website: "",
    about: "",
    age: "",
    mobileNumber: "",
    gender: "",
    profilePicURL:''
  });

  const handleClickForSubmitSignUp = () => {
    handleClickOfSubmit(formValues);
  };
  const isDisabled = !formValues.email || !formValues.password ;
  const [errorToshow , setError] = useState('')
  return (
    <>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        p={6}
        m="10px auto"
        as="form"
      >
        <Stepper display={{ base: "none", md: "flex" }} index={step - 1}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
        {step === 1 ? (
          <Form1 formValues={formValues} setFormValues={setFormValues} disabled={errorToshow}/>
        ) : step === 2 ? (
          <Form2 formValues={formValues} setFormValues={setFormValues} />
        ) : (
          <Form3 formValues={formValues} setFormValues={setFormValues} />
        )}
        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent={step == 1 ? "end" : "space-between"}>
            {step > 1 ? (
              <MotionArrowLeftIcon
              cursor={isDisabled ? "not-allowed" : "pointer"}
                fontSize="24px"
                color="green"
                onClick={() => {
                  setStep(step - 1);
                  setProgress(progress - 33.33);
                }}
                whileHover={{ scale: 1.2, rotate: 90 }}
                whileTap={{ scale: 0.9 }}
                animate={{ scale: [1, 1.5, 1], opacity: [1, 0.5, 1] }}
                transition={{ repeat: Infinity, duration: 2, ease: "linear" }}
                _focus={{ outline: 'none' }}
              />
            ) : (
              <></>
            )}
            {step <= 2 ? (
              <MotionArrowRightIcon
                cursor={isDisabled  ? "not-allowed" : "pointer"}
                color={isDisabled ? 'gray' : 'green'}
                fontSize="24px"
                onClick={() => {
                  if(!isDisabled && !formValues.email.includes('@')){
                    setError('Enter a valid email')
                    return ;
                  }
                  if(isDisabled){
                    setError('Fill all the required fields')
                    return 
                  }else{
                    setError('')
                  }
                  setStep(step + 1);
                  if (step === 3) {
                    setProgress(100);
                  } else {
                    setProgress(progress + 33.33);
                  }
                }}
                _disabled={!formValues.email || !formValues.password || !formValues.mobileNumber}
                whileHover={{ scale: 1.2, rotate: 90 }}
                whileTap={{ scale: 0.9 }}
                animate={{ scale: [1, 1.5, 1], opacity: [1, 0.5, 1] }}
                transition={{ repeat: Infinity, duration: 2, ease: "linear" }}
                _focus={{ outline: 'none' }}
              />
            ) : (
              <></>
            )}
            {step === 3 ? (
              <Button
                w="7rem"
                colorscheme="red"
                variant="solid"
                isLoading={loading}
                onClick={handleClickForSubmitSignUp}
              >
                Submit
              </Button>
            ) : null}
          </Flex>
        </ButtonGroup>
        {step == 1 && <><Box position="relative" mt={5} padding="0">
          <Divider />
          <AbsoluteCenter bg='#161815' color={'white'}px="4">
            or
          </AbsoluteCenter>
        </Box>
        <Box mt={5}>
          <GoogleAuth prefix={'Sign up'} />
        </Box></>}
        
      </Box>
    </>
  );
}
