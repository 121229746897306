'use client'

import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react'
import { IoAnalyticsSharp, IoLogoBitcoin, IoSearchSharp } from 'react-icons/io5'
import { ReactElement } from 'react'
import { FaUsersRays } from 'react-icons/fa6'
import { SiStorybook } from 'react-icons/si'
import { FaMusic } from 'react-icons/fa'
import { MdOutlineAnimation } from 'react-icons/md'
import { CiTimer } from 'react-icons/ci'


const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  )
}

export default function Features() {
  return (
    <Container maxW={'1450px'} py={12} mt={{base:5,md:32}} bg={'var(--main-card-color)'} borderRadius={8} color={'var(--primary-text-color)'}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={'uppercase'}
            fontWeight={600}
            fontSize={'sm'}
            bg={"var(--highlighted-color)"}
            color={"black"}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}>
            Our Story
          </Text>
          <Heading>A digital Product design agency</Heading>
          <Text  fontSize={'lg'}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
            eirmod tempor invidunt ut labore
          </Text>
          <Stack
            spacing={6}
            divider={
              <StackDivider borderColor={useColorModeValue('gray.100', 'gray.700')} />}
            >
            <Feature
              icon={<Icon as={FaUsersRays} color={'purple.500'} w={5} h={5} />}
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text={'User-Friendly Interface and Navigation'}
            />
            <Feature
              icon={<Icon as={SiStorybook} color={'yellow.500'} w={5} h={5} />}
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'Dynamic Visual Storytelling Experiences'}
            />
            <Feature
              icon={<Icon as={FaMusic} color={'green.500'} w={5} h={5} />}
              iconBg={useColorModeValue('green.100', 'green.900')}
              text={'Immersive Brand Narratives with Music'}
            />
            <Feature
              icon={<Icon as={MdOutlineAnimation} color={'purple.500'} w={5} h={5} />}
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text={'Engaging Animations for Maximum Impact'}
            />
            <Feature
              icon={<Icon as={CiTimer} color={'purple.500'} w={5} h={5} />}
              iconBg={useColorModeValue('green.100', 'green.900')}
              text={'Real-Time Analytics and Performance Insights'}
            />
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={
              'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            }
            objectFit={'cover'}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  )
}