import { Box, Button, Flex, IconButton, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { FaPlus } from 'react-icons/fa'
import UploadMusicModal from './UploadMusicModal'
import { FaUpload } from 'react-icons/fa6'

const TopSection = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const isMobile = useBreakpointValue({base:true,md:false})

  return (
    <>
        <Flex justifyContent={"space-between"}>
            <Box>
                <Text fontSize={isMobile ? "xl" :"3xl"} height={'100%'} display={"flex"} alignItems={'center'}>My Library</Text>
            </Box>
            <Box>
                {!isMobile ? <Button leftIcon={<FaPlus />} borderRadius={"12px"} onClick={onOpen}>Upload Music</Button> :
                <IconButton
                    colorScheme='green'
                    variant='outline'
                    onClick={onOpen}
                    aria-label='Upload'
                    icon={<FaUpload />}
                    
/>}
            </Box>
        </Flex>
        <UploadMusicModal isOpen={isOpen} onClose={onClose}/>
        
    </>
  )
}

export default TopSection
