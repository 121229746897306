import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth, firestore ,storage} from "../firebase/firebase";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import useShowToast from "./useShowToast";
import useAuthStore from "../store/authStore";
import { useModalVisibility } from "../context/ModalToast/useModalToast";
import { getDownloadURL, ref, uploadString } from "firebase/storage";

const useSignUpWithEmailAndPassword = () => {
	const [createUserWithEmailAndPassword, , loading, error] = useCreateUserWithEmailAndPassword(auth);
	const showToast = useShowToast();
	const loginUser = useAuthStore((state) => state.login);
    const modal = useModalVisibility()

	const signup = async (inputs) => {
		if (!inputs.email || !inputs.password) {
			showToast("Error", "Please fill all the fields", "error");
			return;
		}

		// const usersRef = collection(firestore, "users");

		// const q = query(usersRef, where("username", "==", inputs.username));
		// const querySnapshot = await getDocs(q);

		// if (!querySnapshot.empty) {
		// 	showToast("Error", "Username already exists", "error");
		// 	return;
		// }

		try {
			const newUser = await createUserWithEmailAndPassword(inputs.email, inputs.password);
			if (!newUser && error) {
				showToast("Error", error.message, "error");
				return;
			}
            let profilePicURL = "";
			if (newUser) {

                // Uploading Profile Pic

                const profilePicRef = ref(storage, `avatars/${newUser.user.uid}`);
				await uploadString(profilePicRef, inputs?.profilePicURL, 'data_url');
				profilePicURL = await getDownloadURL(profilePicRef);

				const userDoc = {
					uid: newUser.user.uid,
					email: inputs.email,
					firstName: inputs.firstName,
					lastName: inputs.lastName,
                    mobileNumber:inputs.mobileNumber,
                    gender:inputs.gender,
                    age:inputs.age,
					profilePicURL: profilePicURL,
					posts: [],
					createdAt: Date.now(),
				};
				await setDoc(doc(firestore, "users", newUser.user.uid), userDoc);
				localStorage.setItem("user-info", JSON.stringify(userDoc));
				loginUser(userDoc);
			}
		} catch (error) {
			showToast("Error", error.message, "error");
		}
	};

	return { loading, error, signup };
};

export default useSignUpWithEmailAndPassword;