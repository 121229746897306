import React from 'react'
import TopSection from './Components/TopSection'
import SongList from './Components/SongListData'

const MyLibrary = () => {
  return (
    <>
        <TopSection/>
        <SongList/>
    </>
  )
}

export default MyLibrary
