import { Box, Button, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

import AccountSettings from './AccountSettings'
import Actions from './Actions'
import CompanySettings from './CompanySettings'
import Notifications from './Notifications'
import ButtonCuts from '../Button/ButtonCuts'
import { useState } from 'react'

const Content = () => {
  const tabs = ['Account Settings', 'Company Settings', 'Notifications']

  const [activeTab , setActiveTab] = useState('')

  return (
    <Box
      as="main"
      flex={3}
      d="flex"
      flexDir="column"
      justifyContent="space-between"
      pt={5}
      bg="var(--main-card-color)"
      color={"var(--primary-text-color)"}
      rounded="md"
      boxShadow='rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
      // borderWidth={1}
      // borderColor="gray.200"
      style={{ transform: 'translateY(-100px)' }}
    >
      <Tabs>
        <TabList px={5}>
          {tabs.map(tab => (
            <Tab
              key={tab}
              mx={3}
              px={0}
              py={3}
              fontWeight="semibold"
              color="brand.cadet"
              borderBottomWidth={1}
              _active={{ bg: 'transparent' ,color: 'white'}}
              _selected={{ color: 'var(--highlighted-color)', borderColor: 'black' }}
            >
              {tab}
            </Tab>
          ))}
        </TabList>

        <TabPanels px={3} mt={5}>
          <TabPanel>
            <AccountSettings />
          </TabPanel>
          <TabPanel>
            <CompanySettings />
          </TabPanel>
          <TabPanel>
            <Notifications />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box w="100%" p="20px">
        <Button w="100%" colorscheme="green">Update</Button>
      </Box>
      
    </Box>
  )
}

export default Content
