
import { BottomNavigation, BottomNavigationIcon, BottomNavigationItem, BottomNavigationLabel } from 'chakra-ui-bottom-navigation';
import { useEffect, useState } from 'react';
import { FaHome, FaUser } from 'react-icons/fa';
import { FiCompass, FiTrendingUp } from 'react-icons/fi';
import {Link, useLocation} from "react-router-dom"


export default function BottomNavigationBar () {
    const [value, setValue] = useState(0);

    const { pathname } = useLocation();

    useEffect(()=>{
      if(pathname=='/dashboard/profile'){
        setValue(3)
      }else if(pathname == '/dashboard/trending'){
        setValue(1)
      }else if(pathname == '/dashboard/explore'){
        setValue(2)
      }else{
        setValue(0)
      }
    },[])
  
    return (
      <BottomNavigation value={value} p="12px" color='var(--highlighted-color)' onChange={setValue} borderRadius={"12px"} backdropFilter='blur(30px)' backgroundColor='rgba(255, 255, 255, 0.5)'>
        <BottomNavigationItem>
            <Link to='/dashboard'>
                <BottomNavigationIcon fontSize={"30px"} as={FaHome}/>
            </Link>
          
        </BottomNavigationItem>

        <BottomNavigationItem>
        <Link to='/dashboard/trending'>
          <BottomNavigationIcon fontSize={"30px"} as = {FiTrendingUp} />
          </Link>
        </BottomNavigationItem>
  
        <BottomNavigationItem>
        <Link to='/dashboard/explore'>
          <BottomNavigationIcon fontSize={"30px"} as = {FiCompass} />
          </Link>
        </BottomNavigationItem>
  
        <BottomNavigationItem>
          <Link to='/dashboard/profile'>
            <BottomNavigationIcon fontSize={"30px"} as={FaUser}/>
          </Link>
        </BottomNavigationItem>

      </BottomNavigation>
    );
  };
  