"use client";
import promoL from "../../assets/Promol.png";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  Drawer,
  DrawerContent,
  useDisclosure,
  Image,
  Button,
  Stack,
  useColorModeValue,
  useColorMode,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  MenuDivider,
  Text,
  useBreakpoint,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiUser,
} from "react-icons/fi";
import MenuToggle from "../MenuToggle/MenuToggle";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion";
import RoutesDashboard from "../../pages/DashboardRoutes/Routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { AlertDialogBox } from "../AlertDialog/AlertDialog";
import { useContext, useState } from "react";
import useLogout from "../../hooks/useLogout";
import { PageLayoutSpinner } from "../../Layout/PageLayout";
import { RiArrowGoBackLine } from "react-icons/ri";
import useAuthStore from "../../store/authStore";
import BottomNavigationBar from "../BottomNavigation/BottomNavigation";
import ThemeChangerButton from "../Button/ThemeChanger/ThemeChangerButton";
import { AddIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import AudioModal from "../Modal/Audio/AudioModals";
import RotatingCDIcon from "../AnimatedIcons/RotatingCdIcon";
import { AudioPlayerContext } from "../../context/Audio/AudioPlayerContext";

const LinkItems = [
  { name: "Home", icon: FiHome, link: "home" },
  { name: "My Library", icon: FiTrendingUp, link: "trending" },
  { name: "Explore", icon: FiCompass, link: "explore" },
  { name: "Profile", icon: FiUser, link: "profile" },
];

const confetti = {
  light: {
    primary: '4299E1',
    secondary: 'var(--highlighted-color)',
  },

  dark: {
    primary: '1A365D',
    secondary: 'var(--highlighted-color)',
  },
}


const MotionBox = motion(Box);

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg="var(--primary-bg-color)"
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="100vh"
      {...rest}
    >
      <Flex h="10vh" alignItems="center" mx="8" justifyContent="space-between">
      <Link to="/"><Image src={promoL} w={{ base: "30%", md: "100%" }} /></Link>
        <MotionBox
          display={{ base: "flex", md: "none" }}
          initial={{ rotate: 0 }}
          animate={"360"}
          transition={{ duration: 0.2 }}
        >
          <IoClose
            color="var(--highlighted-color)"
            fontSize={"32px"}
            onClick={onClose}
          />
        </MotionBox>
      </Flex>
      <Flex h="90vh" justifyContent="space-between" flexDirection={"column"}>
        <Flex flexDirection={"column"} gap={2}>
          {LinkItems.map((link) => (
            <NavItem key={link.name} icon={link.icon} link={link.link} onClose={onClose}>
              {link.name}
            </NavItem>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

const NavItem = ({ icon, children, link, onClose, ...rest }) => {
  const location = useLocation();
  const { colorMode } = useColorMode()
  let pathname = location.pathname.split("/")[2];
  const isActive = pathname === link;
  const handleClose = () => {
    onClose()
  }
  return (
    <Link to={link}>
      <Box style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          gap={2}
          onClick={handleClose}
          borderRadius="lg"
          role="group"
          color={isActive ? (colorMode == 'light' ? 'white' : 'black') : "var(--primary-text-color)"}
          bg={isActive ? "var(--highlighted-color)" : "transparent"}
          cursor="pointer"
          _hover={{
            // bg: "var(--highlighted-color-light)",
            color: isActive ? (colorMode == 'light' ? 'white' : 'black') : "var(--highlighted-color-light)"
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "var(--primary-text-color)",
              }}

              as={icon}
            />
          )}
          {children}
        </Flex>
      </Box>
    </Link>
  );
};

const MobileNav = ({ onOpen, onClose,isOpen, ...rest }) => {
  const {isPlaying} = useContext(AudioPlayerContext)
  const user = useAuthStore((state) => state.user)
  const { colorMode, toggleColorMode } = useColorMode();
  const redirect = useNavigate()
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isAletOpen, setIsAlertOPen] = useState(false);
  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const { handleLogout, isLoggingOut } = useLogout();
  const [value, setValue] = useState({
    header: '',
    message: '',
    value: ''
  })

  const [text, setText] = useState('Loading...')

  const emittier = (value) => {
    if (value == 'logout') {
      setText('Loggin You Out...')
      setIsLocalLoading(true);
      setTimeout(() => {
        handleLogout()
        setIsLocalLoading(false)
      }, 500)
    } else {
      setText('Going Back To Home Screen...')
      setIsLocalLoading(true);
      setTimeout(() => {
        redirect("/")
        setIsLocalLoading(false)
      }, 500)
    }
  }

  const onCloseAlert = () => {
    setIsAlertOPen(false)
  }

  const switchingTheme =()=>{
    setText('Switching Theme...')
      setIsLocalLoading(true);
    setTimeout(() => {
      toggleColorMode()
      setIsLocalLoading(false)
    }, 500)
  }

  if (isLocalLoading) {
    return (
      <PageLayoutSpinner text={text} />
    )
  }
  // const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      pos={'sticky'}
      top={0}
      bg="var(--primary-bg-color)"
      zIndex={1111}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      {/* <MenuToggle onOpen={onOpen} onClose={onClose} /> */}

      <Link to="/"><Image src={promoL} w={{ base: "50%", md: "35%" }} display={{ base: "flex", md: "none" }} /></Link>
      {/* <Button onClick={onOpen}>OPen Music</Button> */}
      {(isPlaying && isMobile) ? <Box onClick={onOpen}><RotatingCDIcon /></Box>:null }
      <HStack spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              as={Avatar}
              cursor={"pointer"}
              aria-label='Options'
              icon={<Avatar
                size={"md"}
                src={user?.profilePicURL}
              />}
              variant='outline'
            />
            <MenuList>
              <Center>
                <Avatar
                  size={{base:'xl',md:'2xl'}}
                  src={user?.profilePicURL}
                />
              </Center>
              <Center mt={3}>
                <Text>{user?.fullName || user?.firstName}</Text>
              </Center>
              <MenuDivider />
              <MenuItem onClick={switchingTheme} icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}>
                  Toggle Theme
              </MenuItem>
              <MenuItem onClick={() => {setIsAlertOPen(true); setValue({ header: 'Go Back To Home', message: 'Are you sure you want to perform this action', value: 'goback' }) }} icon={<RiArrowGoBackLine />}>
                Go Back to Home
              </MenuItem>
              <MenuItem  onClick={() => { setIsAlertOPen(true); setValue({ header: 'Log Out', message: 'Are you sure you want to perform this action', value: 'logout' }) }} icon={<MdLogout colorscheme='red' />}>
                Log Out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <AlertDialogBox isOpen={isAletOpen} emittier={emittier} value={value} onClose={onCloseAlert} />
      </HStack>
      <AudioModal isOpen={isOpen} onClose={onClose}/>
    </Flex>
  );
};

const SidebarDashbaord = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation()
  const isProfile = pathname.includes("/profile")
  return (
    <Box minH="100vh" bg="var(--primary-bg-color)" className="main-body">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        transition="all 0.3s ease-in-out"
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        style={{ top: "80px" }}
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="xs"
        transition="all 0.3s ease-in-out"
      >
        <DrawerContent
          motionProps={{
            variants: {
              enter: {
                x: "0%",
                transition: { duration: 0.3 },
              },
              exit: {
                x: "100%",
                transition: { duration: 0.3 },
              },
            },
          }}
        >
          {/* <SidebarContent onClose={onClose} /> */}
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
      <Box ml={{ base: 0, md: 60 }} p={isProfile ? 0 : { base: 0, md: 5 }} bg="var(--primary-bg-color)"
      >
        <RoutesDashboard />
      </Box>
      {isOpen ? null : <Box display={{ base: "block", md: "none" }}>
        <BottomNavigationBar />
      </Box>}
    </Box>
  );
};

export default SidebarDashbaord;
