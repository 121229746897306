import { useState } from "react";
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { firestore, storage } from "../firebase/firebase";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { ref } from "firebase/storage";

const useUploadData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const base64ToBlob = (base64Data, contentType = "image/jpeg") => {
    const byteCharacters = atob(base64Data.split(",")[1]);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    return new Blob([new Uint8Array(byteArrays)], { type: contentType });
  };

  const uploadData = async (inputs, fileName, user, image) => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const audioStorageRef = ref(storage, `music/${user?.uid}/${fileName}`);
      const imageStorageRef = ref(storage, `images/${user?.uid}/${fileName}-cover`);

      let imageUrl = null;

      if (inputs) {
        await uploadBytes(audioStorageRef, inputs, { contentType: "audio/mpeg" });
        const fileUrl = await getDownloadURL(audioStorageRef);

        let imageFile = image;
        if (typeof image === "string" && image.startsWith("data:image")) {
          imageFile = base64ToBlob(image, image.split(";")[0].split(":")[1]);
        }

        if (imageFile instanceof Blob || imageFile instanceof File) {
          await uploadBytes(imageStorageRef, imageFile, { contentType: imageFile.type });
          imageUrl = await getDownloadURL(imageStorageRef);
        } else {
          throw new Error("Invalid image file format. Please upload a valid image file.");
        }

        const q = query(
          collection(firestore, "songs"),
          where("uid", "==", user.uid),
          where("trackName", "==", fileName)
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          await addDoc(collection(firestore, "songs"), {
            uid: user.uid,
            trackName: fileName,
            artist: user.fullName || user.firstName,
            fileUrl,
            imageUrl: imageUrl || "",
            uploadDate: new Date().toISOString(),
          });
        } else {
          const existingDoc = querySnapshot.docs[0];
          await updateDoc(doc(firestore, "songs", existingDoc.id), {
            fileUrl,
            imageUrl: imageUrl || "",
            uploadDate: new Date().toISOString(),
          });
        }

        setSuccess(true);
      }
    } catch (err) {
      setError(err.message);
      setSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return { uploadData, isLoading, error, success };
};

export default useUploadData;
