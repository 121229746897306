import { create } from "zustand";

const useFeaturedArtistTracks = create((set) => ({
	data: [],
    loader:false,
    setLoader:(loading)=>set({loading}),
    setData:(data)=>set({data}),
    error:false
}));

export default useFeaturedArtistTracks;