import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom'
import DashboardHome from './DashboardHome';
import { auth } from '../../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import DashboardExplore from './DashboardExplore';
import DashboardProfile from './DashboardProfile';
import DashboardTrending from './DashboardTrending';


const RoutesDashboard = () => {


  const [authUser] = useAuthState(auth);
  return (
    // <Box style={{ maxHeight: '100vh' }}>
    <Routes>
        <Route path="home" element={authUser ? <DashboardHome /> : <Navigate to="/"/> }></Route>
        <Route path="explore" element={authUser ? <DashboardExplore /> : <Navigate to="/"/> }></Route>
        <Route path="profile/*" element={authUser ? <DashboardProfile /> : <Navigate to="/"/> }></Route>
        <Route path="trending" element={authUser ? <DashboardTrending /> : <Navigate to="/"/> }></Route>
        <Route path="/" element={authUser ? <Navigate to="home" /> : <Navigate to="/" />} />
    </Routes>
    // </Box>
  )
}

export default RoutesDashboard
