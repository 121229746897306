import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import wavesurfer from 'wavesurfer.js';
import { FileContext } from '../../context/Audio/AudioFileContext';

export default function DashboardArtistCard({ user }) {
  const wavesurferRef = useRef(null);
  const { fileURL } = useContext(FileContext);
  const [wavesurferObj, setWavesurferObj] = useState(null);
  const [playing, setPlaying] = useState(false); // Track playing state

  // Create the waveform instance
  // useEffect(() => {
  //   if (wavesurferRef.current && !wavesurferObj) {
  //     setWavesurferObj(
  //       wavesurfer.create({
  //         container: wavesurferRef.current,
  //         // autoCenter: true,
  //         cursorColor: 'none',
  //         loopSelection: true,
  //         waveColor: '#6a8f7a',
  //         progressColor: '#2cc970',
  //         barWidth: 3,
  //         responsive: true,
  //         hideScrollbar: true,
  //         barRadius: 3,
  //         height:60
  //       })
  //     );
  //   }
  // }, [wavesurferRef, wavesurferObj]);

  // Load the file URL
  useEffect(() => {
    if (fileURL && wavesurferObj) {
      wavesurferObj.load(fileURL);
    }
  }, [fileURL, wavesurferObj]);

  // Handle play/pause button click
  const handlePlayPause = () => {
    if (wavesurferObj) {
      if (playing) {
        wavesurferObj.pause();
      } else {
        wavesurferObj.play();
      }
      setPlaying(!playing);
    }
  };

  return (
    <Center py={6}>
      <Box
        maxW={'320px'}
        w={'full'}
        bg='var(--main-card-color)'
        boxShadow={'2xl'}
        rounded={'lg'}
        p={6}
        textAlign={'center'}>
        <Avatar
          size={'xl'}
          src={user.image_url}
          mb={4}
          pos={'relative'}
          _after={{
            content: '""',
            w: 4,
            h: 4,
            bg: 'green.300',
            border: '2px solid white',
            rounded: 'full',
            pos: 'absolute',
            bottom: 0,
            right: 3,
          }}
        />
        <Heading fontSize={'2xl'} fontFamily={'body'}>
          {user.name}
        </Heading>
        <Text fontWeight={600} color={'gray.500'} mb={4}>
          @{user.username}
        </Text>
        <Text
          textAlign={'center'}
          color={useColorModeValue('gray.700', 'gray.400')}
          px={3}>
          {user.about}{' '}
          <Text color={'blue.400'}>tag</Text> me in your posts
        </Text>


        <Stack mt={8} direction={'row'} spacing={4}>
          <Button
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            _focus={{
              bg: 'gray.200',
            }}>
            Message
          </Button>
          <Button
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            bg={'blue.400'}
            color={'white'}
            boxShadow={
              '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
            }
            _hover={{
              bg: 'blue.500',
            }}
            _focus={{
              bg: 'blue.500',
            }}>
            Hire
          </Button>
        </Stack>
      </Box>
    </Center>
  );
}
