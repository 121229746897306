import { Box, Flex, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Text, useBreakpointValue } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { BsDownload, BsFillSkipBackwardFill, BsFillSkipForwardFill, BsPauseFill, BsPlayFill, BsRepeat } from "react-icons/bs";
import { TbRepeatOff, TbRepeatOnce } from "react-icons/tb";
import { AudioPlayerContext } from '../../../context/Audio/AudioPlayerContext';

const MobileScreenPlayer = () => {
    const {
        currentTrack,
        isPlaying,
        playTrack,
        pauseTrack,
        progress,
        seekTrack
    } = useContext(AudioPlayerContext);

    const isMobile = useBreakpointValue({ base: true, md: false });

    if (!currentTrack) return null;

    return (
        <div className='main' style={{ display: isMobile ? 'block' : 'none' }}>
            <div className="player-container">
                <Box className="img-container">
                    <img src={currentTrack?.imageUrl} alt="Album Art" />
                </Box>
                <Box className="data">
                    <Text className="title">{currentTrack.trackName}</Text>
                    <Text className="artist">{currentTrack.artist}</Text>
                </Box>
                <Box className="progress-container">
                    <Slider
                        aria-label='slider-ex-4'
                        defaultValue={0}
                        value={progress}
                        onChange={seekTrack}
                    >
                        <SliderMark value={0} mt='1' mr='-3.5' fontSize='sm'>0:00</SliderMark>
                        <SliderMark value={100} mt='1' ml='-3.5' fontSize='sm'>0:30</SliderMark>
                        <SliderTrack bg='red.100'>
                            <SliderFilledTrack bg='tomato' />
                        </SliderTrack>
                        <SliderThumb boxSize={3}>
                            <Box color='tomato' />
                        </SliderThumb>
                    </Slider>
                </Box>
                <div className="player-controls">
                    <BsRepeat className='fas' />
                    <BsFillSkipBackwardFill className='fas' />
                    {isPlaying ? (
                        <BsPauseFill className='fas' onClick={pauseTrack} />
                    ) : (
                        <BsPlayFill className='fas' onClick={ () => playTrack(currentTrack)} />
                    )}
                    <BsFillSkipForwardFill className='fas' />
                    {/* <a href={currentSong.fileUrl} download={currentSong.trackName}> */}
                        <BsDownload className='fas' />
                    {/* </a> */}
                </div>
            </div>
        </div>
    );
}

export default MobileScreenPlayer;
