// ThemeChangerButton.js

import { useColorMode, IconButton, useColorModeValue } from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

const ThemeChangerButton = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const icon = colorMode === 'light' ? <MoonIcon /> : <SunIcon />;
  const nextMode = colorMode === 'light' ? 'dark' : 'light';

  const iconColor = useColorModeValue('gray.800', 'gray.200');

  return (
    <IconButton
      aria-label={`Switch to ${nextMode} mode`}
      icon={
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.2 }}
        >
          {icon}
        </motion.div>
      }
      onClick={toggleColorMode}
      color={iconColor}
      variant="ghost"
      size="lg"
      as={motion.button}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    />
  );
};

export default ThemeChangerButton;

